import React, { Component } from 'react'
import { Container, Card, CardImg, CardBody, CardTitle, Row, Col} from 'reactstrap';



export class Main extends Component{

   

    goSifo= () =>{
        let myurl = 'https://forzasifo.digital/sifo';
       // console.log(myurl);
        window.location.replace(myurl);
       
    }

    goSifo2= () =>{
        let myurl = 'https://forzasifo.digital/sifo2';
       // console.log(myurl);
        window.location.replace(myurl);
       
    }
   
    componentDidMount(){
       
       
    }

  
    render(){
        
        return(
            <div >
                
                <div className="text-center" >
                    <Container>
                        <div style={{position: 'relative', width: '100%', height:60}}>
                            
                            <h4 className="text-center" style={{position: 'absolute', top: '300px', left: '0', width: '100%'}}>
                                <span style={{color: 'white', padding: '10px', fontSize:30}}>
                                    GRUPO OIKOS - SISTEMAS INTEGRALES
                                </span>
                            </h4>
                            <br/><br/><br/><br/>

                        </div>
                        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                        <div style={{paddingLeft:150}}>
                            <Row>
                                <Col>
                                <Card onClick={this.goSifo.bind()} style={{width:300, height:250, cursor:'pointer'}}>
                                <CardImg top width="100%" src="https://forzasifo.digital/sifo/services/sifo_imagenes/logo_forzatel.png" alt="Card image cap" />
                                <CardBody>
                                <CardTitle><h2>SIFO</h2></CardTitle>
                                </CardBody>
                                </Card>
                                </Col>
                                <Col>
                                <Card onClick={this.goSifo2.bind()} style={{width:300, height:250, cursor:'pointer'}}>
                                <CardImg style={{paddingTop:20}} top width="100%" src="https://forzasifo.digital/sifo2/services/sifo_imagenes/logo_forzatel.png" alt="Card image cap" />
                                <CardBody>
                                <CardTitle><h2>SIFO 2</h2></CardTitle>
                                </CardBody>
                                </Card>
                                </Col>
                            </Row>
                        </div>
                        
                      
                    </Container>
                    
                </div>
                
                
                
                
                <br/>
                
                
            </div>
        )
    }
    
}
export default Main

